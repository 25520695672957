#main-header {
    position: relative;
    width: 100%;
    text-align: center;
    height: var(--main-header-size);
    transition: height 100ms ease-out;
    z-index: 1;
    background-color: white;
}

#main-header.extended {
    height: var(--main-header-size-extended);
    box-shadow: 0 1px 0 0 #dfe2e6;
}
#main-header.collapsed {
    height: var(--main-header-size-collapsed);
}

#main-header.extended .main-header-title {
    box-shadow: 0 1px 0 0 #dfe2e6;
    top: -100px !important;
}

.main-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--main-header-top-size);
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
    background-color: white;
}

.main-header-logo {
    position: relative;
    height: 32px;
    width: 163px;
}
.main-header-logo img {
    position: absolute;
    top: 0;
    left: 0;
    fill: green;
    height: 32px;
}
.main-header-logo svg {
    fill: green;
}

.main-header-title {
    position: fixed;
    top: 0px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    cursor: pointer;
    width: fit-content;
    z-index: 2;
    background-color: white;
    transition: top ease-out 100ms;
    box-shadow: 0 1px 0 0 #dfe2e6;
    width: 100%;
    height: 50px;
    padding:0 0 20px 160px ;
}
.main-header-title.visible {
    top: var(--main-header-top-size);
}

#menu-button{
    margin: 20px 32px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

#menu-return-button {
    position: relative;
    top: 1px;
    padding: 10px;
}
#menu-return-button svg {
    fill: var(--text-color)
}

.main-header-notify-icon {
    position: relative;
    cursor: pointer;
    margin: 20px 32px;
    background-color: #eee;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-header-notify-icon.hidden {
    background-color: transparent;
    cursor: default;
}

.main-header-notify-number {
    color: white;
    background-color: tomato;
    border-radius: 50%;
    font-size: 10px;
    height: 14px;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 4px;
    right: -4px;
    z-index: 6;
    pointer-events: none;
}

.main-header-notify-icon.animate {
    animation: ring 250ms;
    animation-iteration-count: 5;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(-15deg);
    }
    80% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.main-header-extended-content {
    position: absolute;
    top: var(--main-header-top-size);
    height: 290px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}