.internet-status-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    height: 200px;
    width: 100%;
    margin: 0 auto;
}

.internet-speed-indicator {
    position: absolute;
    left: calc(50% - 330px);
    top: 0px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
}

.internet-power-indicator {
    position: absolute;
    right: calc(50% - 330px);
    top: 0px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
}

.offline .internet-status-outer {
    --color: tomato !important;
}
.offline .internet-status {
    color: #fe2b1f;
}

.internet-status-outer {
    position: absolute;
    top: 0px;
    --color: rgba(1,255,1,0.6);
    width: 200px;
    height: 200px;
    border-width: 2px solid;
    border-color: var(--itb-green) transparent;
    border-style: solid;
    border-radius: 50%;
    margin: 0 auto;
    animation: spin-right 2.5s linear infinite;
}
.internet-status {
    position: absolute;
    top: 70px;
    text-align: center;
    font-size: 29px;
    color: var(--itb-green);
}
.internet-status-title {
    font-size: 16px;
    display: block;
    font-weight: normal;
    color: #9eadba;
    font-size: 21px;
}

@keyframes pulse{
    0% {

        box-shadow: 0px 0px 2px 0px var(--error-color);
    }
    50% {

        box-shadow: 3px 3px 8px -2px tomato, -3px -3px 8px -2px tomato
    }
    100% {

        box-shadow: 0px 0px 2px 0px var(--error-color);
    }
}
@keyframes spin-right{
    from {
        transform: rotate(0deg)
    }
    to {
        transform:  rotate(360deg)
    }
}
@keyframes spin-left{
    from {
        transform: rotate(360deg)
    }
    to {
        transform:  rotate(0deg)
    }
}

@media screen and (max-width: 1000px){
    .internet-speed-indicator {
        left: calc(50% - 270px);
    }
    .internet-power-indicator {
        right: calc(50% - 270px);
    }
}

@media screen and (max-width: 720px){
    .internet-speed-indicator {
        left: calc(50% - 200px);
    }
    .internet-power-indicator {
        right: calc(50% - 200px);
    }
    .internet-status-outer {
        top: 25px;
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 460px){
    .internet-speed-indicator {
        left: calc(50% - 170px);
    }
    .internet-power-indicator {
        right: calc(50% - 170px);
    }
    .internet-status-outer {
        top: 40px;
        width: 100px;
        height: 100px;
    }
    .internet-status{
        font-size: 19px;
    }
    .internet-status-title {
    font-size: 15px;
    }
}
