#advanced-igmp-mld-snooping-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
}

#advanced-igmp-mld-snooping-form {
    max-width: 450px;
    margin: 0 auto;
    text-align: left;
}