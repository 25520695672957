#ipv6-route-list-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
    overflow-x: auto;
}

.ipv6-route-list-list-wrapper {
    overflow-x: auto;
    margin: 25px 0;
    padding: 0
}

#ipv6-route-list-page .list {
    margin: 0 auto;
    width: 1050px;
}

#ipv6-route-list-page .list-headers,
#ipv6-route-list-page .list-line-entry {
    padding-left: 0px;
}

#ipv6-route-list-form {
    max-width: 450px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

#ipv6-route-list-no-rules .info-card {
    max-width: 50%;
}