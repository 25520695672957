#advanced-qos-policy-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#advanced-qos-policy-page .itb-button-container {
    margin: 20px auto;
}

#advanced-qos-policy-form {
    margin: 0 auto;
    text-align: left;
}

.weight-warning {
    color: orange;
    font-size: 0.75em;
    display: flex;
}

.weight-warning strong{
    margin: 0 auto;
}

.qos-weight-warnings{
    margin-bottom: 20px;
}

.weight-error {
    color: red;
    font-size: 0.75em;
    display: flex;
}

.weight-error strong{
    margin: 0 auto;
}

