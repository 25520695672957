#advanced-global-routing-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
    overflow-x: auto;
}

#advanced-global-routing-page .list {
    min-width: var(--width-box-medium);
    margin: 0 auto;
}

.advanced-global-routing-list-wrapper {
    margin: 20px auto;
    overflow-x: auto;
}

#advanced-global-routing-page .info-card {
    max-width: 100%;
}

#advanced-global-routing-form {
    max-width: 450px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

#advanced-global-routing-no-forwards .info-card {
    max-width: 50%;
}