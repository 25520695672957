form {
    position: relative;
}

.form-segment {
    position: relative;
    display: none;
    padding: 30px;
    border-radius: 6px;
    padding-bottom: 70px;
    margin-bottom: 30px;
}

.form-segment-title {
    border-bottom: 1px solid var(--light-gray);
    font-weight: bold;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.form-segment-active {
    display: block
}

.form-segment-button {
    width: 130px;
    height: 45px;
    border: 1px solid var(--itb-green);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    fill: var(--itb-green);
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    color: var(--itb-green);
    user-select: none;
    position: relative;
    background-color: white;
}

.form-segment-next svg {
    height: 25px;
    position: absolute;
    right: 8px;
}

.form-segment-next[disabled] svg {
    height: 25px;
    position: absolute;
    right: 8px;
}

.form-segment-previous svg {
    height: 22px;
    position: absolute;
    left: 8px;
}

.form-segment-next {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding-right: 20px;
}

.form-segment-next[disabled] {
    cursor: not-allowed;
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding-right: 20px;
    color: var(--gray);
    fill: var(--gray);
    border: 1px solid var(--gray);
}

.form-segment-previous {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding-left: 20px;
}

.form-steps-indicator {
    height: 20px;
}

.form-step {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: var(--medium-gray);
    margin: 2px;
    border-radius: 50%;
}
.form-step-active {
    background-color: var(--gray);
}