.card-button {
    width: 210px;
    min-width: 148px;
    height: 144px;
    padding: 26px 16px;
    border-radius: 4px;
    border: 1px solid #dfe2e6;
    cursor: pointer;
    user-select: none;
    margin: 10px;
    text-align: left;
    position: relative;
}

.card-button-label {
    color: #859aa7;
    font-size: 17px;
    height: 24px;
    margin: 8px 0 4px;
}

.card-button-value {
    font-size: 23px;
}

.card-button-icon {
}

