#ipv6-prefix-delegation-page {
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
    margin: 0 auto;
    padding-bottom: 40px;
}

#ipv6-prefix-delegation-form {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}
#ipv6-prefix-delegation-form .mt2 {
    margin-top: 0;
}