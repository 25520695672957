#voip-advanced-sip-page {
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
    margin: 0 auto;
    padding-bottom: 40px;
}

#voip-advanced-sip-page .info-card {
    max-width: var(--width-box-small);
    margin: 0 auto;
    margin-top: 32px;
}

.voip-advanced-sip-section {
    width: 100%;
}

.voip-advanced-sip-wrapper {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .voip-advanced-sip-wrapper {
        display: flex;
        flex-direction: column;
    }
}
