#service-rip-config-page {
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
    margin: 0 auto;
    padding-bottom: 40px;
}

.service-rip-config-list-wrapper  {
    width: var(--width-box-small);
    max-width: var(--max-width-box);
    overflow-x: auto;
    margin: 0 auto;
}


.service-rip-config-list-wrapper .list {
    width: var(--width-box-small);
    margin: 0 auto;
}

#service-rip-config-page .info-card {
    max-width: 100%;
}

#service-rip-config-form {
    width: var(--width-box-small);
    max-width: 100%;
    margin: 0 auto;
}

#rip-config-no-configs .info-card {
    max-width: 50%;
}

.rip-config-new-register-form {
    margin: 0 auto;
}

.rip-config-subtitle {
    padding-top: 50px;
}

.rip-config-list-empty-label {
    margin-bottom: 50px;
}