.info-popup {
    position: relative;
    z-index: 2;
}

.info-popup svg {
    cursor: pointer;
    fill: #92a4b1;
}

.info-popup:hover .tooltiptext {
    display: block;
}