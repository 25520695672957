#tr069-inform-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
}

#tr069-inform-infocard {
    margin-top: 25px;
    max-width: 100%;
}

#tr069-inform-page .tr069-inform-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}

#tr069-inform-page .tr069-inform-info a {
    text-decoration: none;
    color: cornflowerblue;
}
