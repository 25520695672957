#gem-port-statistics-page {
    margin: 0 auto;
}

#gem-port-statistics-page .gemport-hosts-list-wrapper{
    margin: 25px auto;
    max-width: var(--max-width-box);
    overflow-x: auto;
}

#gem-port-statistics-page .list{
    width: var(--width-box-medium);
    margin: 0 auto;
}
