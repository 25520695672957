#firewall-url-blocking-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#firewall-url-blocking-page .info-card {
    max-width: 100%;
}

.firewall-url-blocking-table {
    overflow-x: auto;
    margin: 0;
}

.firewall-url-blocking-table .list {
    margin: 0;
}

#firewall-url-blocking-page strong {
    color: orange;
    font-size: 0.75em;
    display: block;
    margin: 0 auto;
    text-align: center;
}