.itb-input-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 75px;
    position: relative;
    overflow: hidden;
    transition: height 200ms linear;
    text-align: left;
}

.itb-input-field.password-meter {
    height: 150px;
    overflow: visible;
}

.itb-input-field.collapse {
    height: 0px;
    visibility: hidden;
}
.itb-input-field.no-label {
    height: 80px;   
}
.itb-input-field.without-feedback {
    height: 80px
}
.itb-input-field,.inputs-group label{
    padding: 5px 10px;
}
.itb-input-field label {
    font-size: 15px;
    position: absolute;
    top: 16px;
    left: 14px;
    z-index: 2;
    padding: 0 4px;
    background-color: white;
    color: #78909c;
    transition: all ease-out 50ms;
}

.itb-input-field-select-wifi {
    width: 100%;
    height: 55px;
    position: relative;
    overflow: visible;
    transition: height 200ms linear;
    text-align: left;
}

.itb-input-field-select-wifi.password-meter {
    height: 150px;
    overflow: hidden;
}

.itb-input-field-select-wifi.collapse {
    height: 0px;
    visibility: hidden;
}
.itb-input-field-select-wifi.no-label {
    height: 80px;   
}
.itb-input-field-select-wifi.without-feedback {
    height: 80px
}
.itb-input-field-select-wifi.inputs-group label{
    padding: 5px 10px;
}
.itb-input-field-select-wifi label {
    font-size: 15px;
    position: absolute;
    top: 16px;
    left: 14px;
    z-index: 2;
    padding: 0 4px;
    background-color: white;
    color: #78909c;
    transition: all ease-out 50ms;
}

input:focus + label, input:focus + .password-meter-tooltip label {
    top: -2px;
    color: var(--itb-green);
    font-size: 12px;
}
input:valid + label, input:valid + .password-meter-tooltip label {
    top: -2px;
    color: var(--itb-green);
    font-size: 12px;
}


/* Workaround to remove background color for autofill inputs in chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.itb-input-field.error label{
    color: var(--error-color)
}
.itb-input-field.error input{
    border: 1px solid var(--error-color);
}

.itb-input-field.disabled input{
    cursor: not-allowed;
    color: var(--gray);
}

.itb-input-field.disabled label{
    top: -2px;
    font-size: 12px;
}

.inputs-group {
    position: relative;
    height: 142px;
    text-align: left;
}
.inputs-group .itb-input-error-text {
    position: relative;
    bottom: 15px;
    left: 15px;
}
.inputs-group .inputs-line{
    display: flex;
}
.inputs-group.error .itb-input-field input{
    border: 1px solid var(--error-color);
}

.itb-input-error-text{
    color: red;
    font-size: 12px;
    font-weight: lighter;
    padding: 0 5px;
    min-height: 15px;
}

.itb-input {
    margin: 5px 0;
    background-color: white;
    padding: 8px 0px 10px 16px;
    border: 1px solid #cfd8dc;
    border-radius: 4px;
    outline: none;
    height: 40px;
    color: var(--text-color);
    width: 100%;
}
::placeholder{
    color: #c3c3c3;
}

.itb-input-password {
    margin: 5px 0;
    background-color: white;
    padding: 8px 48px 10px 16px;
    border: 1px solid #cfd8dc;
    border-radius: 4px;
    outline: none;
    height: 40px;
    color: var(--text-color);
    width: 100%;
}

.itb-input-md {
    width: 100%;
    min-width: 200px;
    max-width: 450px;
}

.itb-input-password-icon {
    position: absolute;
    right: 14px;
    top: 14px;
    fill: var(--dark-gray);
}

.itb-input-field.no-label .itb-input-password-icon{
    bottom: 35px;
}
.itb-input-field.no-label .itb-input-password-icon.eye-off{
    bottom: 34px;
}

.itb-input-password-icon.eye-off {
    top: 13px;
    fill: var(--gray);
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.itb-input-field .password-features {
    display: flex;
    font-weight: normal;
    margin: 0 5px 5px 5px;
    padding-left: 15px;
    font-size: 14px;
    cursor: default;
}

.itb-input-field .password-features > li {
    list-style-type: none;
    padding-right: 30px;
    position: relative;
    margin-top: 8px;
}

.itb-input-field .password-features > li[ok="1"] {
    color: var(--itb-green);
}

.itb-input-field .password-features > li::before {
    display: inline-block;
    position: absolute;
    vertical-align: baseline;
    content: '•';
    font-size: 32px;
    left: -20px;
    bottom: -8px;
}

.itb-input-field .password-strength {
    font-weight: normal;
}

.itb-input-field .password-strength span {
    color: red;
}

.itb-input-container {
    position: relative;
}

.password-meter-tooltip {
    display: flex;
    justify-content: space-between;
}

.password-meter-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
}