#firewall-dmz-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#firewall-dmz-page .list {
    min-width: 1000px;
}

#firewall-dmz-page .info-card {
    max-width: 100%;
}