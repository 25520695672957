select.itb-input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    height: 40px;
}

.itb-input-field.select label {
    top: 3px;
    left: 22px;
    color: var(--itb-green);
    font-size: 12px;
}

.itb-input-field.select{
    position: relative;
}
.itb-input-field.select::after {
    content: '\203a';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 27px !important;
    right: 20px;
    transform: rotate(90deg);
    font-size: 22px;
    color: #78909c;
    pointer-events: none;
}
.itb-input-field.select.without-feedback::after {
    top: 46px;
}

.itb-input-field-select-wifi label {
    top: -3px;
    left: 22px;
    color: var(--itb-green);
    font-size: 12px;
}

.itb-input-field.select-wifi{
    position: relative;
}
.itb-input-field.select-wifi::after {
    content: '\203a';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 27px !important;
    right: 20px;
    transform: rotate(90deg);
    font-size: 22px;
    color: #78909c;
    pointer-events: none;
}
.itb-input-field.select-wifi.without-feedback::after {
    top: 46px;
}

