#system-acl-ipv6-page {
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
    margin: 0 auto;
    padding-bottom: 40px;
}

.system-acl-ipv6-list {
    margin-top: 35px;
}

#system-acl-ipv6-page .acl-ipv6-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}

#system-acl-ipv6-page .acl-ipv6-info a {
    text-decoration: none;
    color: cornflowerblue;
}

#system-acl-ipv6-no-rules .info-card {
    max-width: 80%;
}

.system-acl-ipv6-list-wrapper {
    padding-left: 0px;
    overflow: auto;
    margin: 0 auto;
    margin-top: -10px;
}

#system-acl-ipv6-page strong {
    color: orange;
    font-size: 0.75em;
    display: block;
    margin: 0 auto;
    text-align: center;
}