#menu-button {
    background: transparent;
    border: none;
    outline: none;
}

#menu-button-inside {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    margin: 10px  10px;
    
}

#menu-container {
    position: fixed;
    top: 0;
    left: calc(var(--menu-width) * -1);
    z-index: 9;
    background-color: #ced2cc;
    height: 100%;
    width: var(--menu-width);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .12);
    transition: left 200ms;
}
#menu-container.open{
    left: 0 !important;
}

.menu-content {
    background-color: #191c23;
    height: 100%;
    width: 100%;
}

.menu-logo {
    padding: 64px 72px;
}

.menu-entries {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100% - 200px);
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.menu-entry{
    position: relative;
    color: white;
    fill: white;
    padding: 20px;
    cursor: pointer;
    font-size: 19px;
}
.menu-entry.active {
    color: var(--itb-green);
}
.menu-entry.active svg {
    fill: var(--itb-green);
}

.menu-entry.hoverable:hover{
    color: #009325;
}
.menu-entry.hoverable:hover svg {
    fill: #009325 !important;
}
.menu-entry.hoverable:hover .menu-down-icon svg path {
    stroke: #009325 !important;
}

.menu-entry svg {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 13px;
    fill: rgba(255,255,255,.8);
    margin: -2px 10px 0 0;
    cursor: pointer;
}
.menu-entry-text {
    padding-left: 30px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    cursor: pointer;
    text-align: left;
}

.menu-entry-text label {
    margin-bottom: 2px;
    cursor: pointer;
}
.menu-entry-text span {
    font-size: 14px;
    padding: 10px 0;
    cursor: pointer;
}

.menu-logout {
    color: var(--light-gray);
    cursor: pointer;
    display: inline;
    margin-left: 5px;
    font-size: 12px;
}
.menu-logout span {
    text-decoration: underline;
}

.menu-down-icon {
    position: absolute;
    top: -6px;
    right: 64px;
}
.menu-down-icon svg{
    position: absolute;
    width: 32px;
    height: 32px;
}
.menu-down-icon.open{
    top: 64px;
    right: -6px;
    transform: rotate(180deg);
}

.submenu {
    border-width: 1px 0;
    border-color: rgba(255, 255, 255, .8);
    border-style: solid;
    margin-top: 20px;
}

.submenu .menu-entry {
    position: relative;
    right: 20px;
    width: var(--menu-width);
    padding:20px 20px 0px 40px;
    height: 98px;
    overflow: hidden;
    animation: SubOpen 100ms ease;
}

.submenu svg {
    left: 40px;
}

@keyframes SubOpen {
    0% {
        padding:0px 20px 0px 40px;
        height: 0;
    }
    100% {
        padding:20px 20px 0px 40px;
        height: 98px;
    }
}

.with-fixed-menu {
    width: calc(100vw - var(--menu-width)) !important;
    margin: 0 0 0 auto;
}