.search-wrapper {
    display: grid;
    width: 130%;
    text-align: center;
}

.search-wrapper .search-erase-button {
    grid-column: 2 / 3;
    position: relative;
    width: 10%;
    top: 25%;
    right: 35%;
    background-color: transparent;
    fill: var(--gray);

}