#voip-history-call-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
    padding-bottom: 40px;
}

#voip-history-call-page .list {
    min-width: 1000px;
}

.voip-history-call-list-wrapper {
    max-width: 100%;
    overflow-x: auto;
    margin: 25px;
    margin-top: 0px;
}

.noelements-card {
    width: 100%;
    padding: 10px;
    margin: 0 auto;
}

.voip-history-call-select-wrapper .select {
    margin-top: 15px;
}