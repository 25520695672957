#service-dynamic-dns-page {
    margin: 0 auto;
    padding-bottom: 40px;
}

#service-dynamic-dns-page .card {
    width: var(--width-box-small);
    max-width: var(--max-width-box);
    margin: 30px auto;
}

#service-dynamic-dns-list-page {
    max-width: var(--max-width-box);
    margin: 0 auto;
}

#service-dynamic-dns-list-page .password-header {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#service-dynamic-dns-list-page .password-header .eye-password {
    cursor: pointer;
    position: absolute;
    margin-left: 5px;
}

#service-dynamic-dns-list-page .list {
    margin: 0 auto;
}

.service-dynamic-dns-list-list-wrapper {
    width: var(--width-box-medium);
    max-width: var(--max-width-box);
    overflow-x: auto;
    margin: 0 auto;
    padding-bottom: 20px;
}

#service-dynamic-dns-form {
    margin: 0 auto;
    text-align: left;
}