.mesh-onboarding-page {
    padding: 45px 0 45px 0;
    display: flex;
    justify-content: center;
}

.mesh-onboarding-content {
    max-width: 480px;
    text-align: center;
}

.mesh-onboarding-title {
    font-size: 19px;
    margin-top: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    text-align: left;
}

.mesh-onboarding-text {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 8px 0 32px;
    color: gray;
    text-align: left;
}

.mesh-onboarding-title.center, .mesh-onboarding-text.center {
    text-align: center;
}

#mesh-onboarding-input {
    margin-top: 20px;
}

.mesh-onboarding-buttons-container {
    margin-top: 20px;
    display: flex;
}

.mesh-onboarding-first-buttons-container {
    margin-top: 20px;
    display: flex;
}

#mesh-onboarding-btn {
    margin-top: 20px;
    width: 210px;
    border-radius: 4px;
    float: right;
}

#mesh-back-to-settings-btn {
    margin-top: 20px;
    width: 210px;
    border-radius: 4px;
    float: left;
}

#mesh-onboarding-btn-restart {
    margin-top: 20px;
    width: 210px;
    border-radius: 4px;
    float: left;
}

.mesh-onboarding-wifi-icon svg path  {
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes loading {
    from {
        stroke-dashoffset: 50; /*9*/ /*50*/
        stroke-dasharray: 50; /*1*/ /*50*/
    }

    to {
        stroke-dashoffset: 1; /*100*/ /*1*/
        stroke-dasharray: 31; /*43*/ /*31*/
    }
}

#mesh-device-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    padding-bottom: 40px;
    margin: 0 auto;
}

#mesh-device-page .collapse-wrapper {
    width: 100%;
    margin: 0 auto;
}



@media only screen and (max-width: 850px){
    #mesh-device-page {
        margin: 50px;
    }
}


#mesh-device-page .list-wrapper{
    overflow-x: auto;
    margin: 0 auto;
}

#mesh-device-page .list-wrapper .info-card{
    margin: 0 auto;
    
}
#mesh-device-page .list-wrapper .info-card #ipv6-details-line{
    float: right;
}

#mesh-device-page .section-divider {
    margin-top: 20px;
}

.device-page-header {
    width: 100%;
}

.device-header-wrapper {
    max-width: 800px;
    display: flex;
    margin: 0 auto;
}

#mesh-device-page .device-page-multicolumn {
    display: flex;
    justify-content: space-between;
}

#mesh-device-page .device-page-multicolumn > div {
    width: calc(50% - min(15px, 1.5%));
}

#mesh-device-page .collapse-box {
    margin: 0 auto;
}

#mesh-device-page #btn-remove-node {
    max-width: 80px;
}

#mesh-device-page .device-node-type {
    font-size: 11px;
    padding: 5px 16px;
    margin: auto;
    border-radius: 12px;
    width: 150px;
    text-align: center;
    color: var(--itb-green);
    border: solid 1px var(--itb-green);
    display: inline-block;
}

.searching-bar {
    background-color: var(--itb-green);
    height: 10px;
}

.searching-bar-container {
    display: flex;
    background-color: var(--light-gray);
    height: 10px;
    width: 480px;
    max-width: 100%;
    margin-top: 15px;
}

#mesh-nodes-page .dashboard-subtitle.mesh {
    display: flex;
    justify-content: space-between;
}

#mesh-nodes-page .dashboard-subtitle.mesh .btn-add-mesh-node {
    display: flex;
    border: solid 1px #dfe2e6;
    border-radius: 3px;
    height: 40px;
    width: 243px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 32px;
    cursor: pointer;
}

#mesh-nodes-page .dashboard-subtitle.mesh .btn-add-mesh-node * {
    cursor: pointer;
}

#mesh-nodes-page .dashboard-subtitle.mesh .btn-add-mesh-node label {
    font-size: 17px;
    font-weight: 600;
}

#mesh-nodes-page .dashboard-subtitle.mesh .btn-add-mesh-node:hover {
    border-color: var(--gray);
}

.mesh-nodes-table .mesh-device-signal {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.mesh-nodes-table .mesh-device-signal > span {
    padding: 10px;
}

#mesh-nodes-page .mesh-no-device-title {
    margin: 8px auto;
    font-size: 19px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text-color);
}

#mesh-nodes-page .mesh-no-device-subtitle {
    margin: 8px auto;
    line-height: 1.4;
    text-align: center;
    color: #a9b6c3;
}

#mesh-nodes-page #dashboard-mesh-container .node-title {
    font-size: 17px;
}

#mesh-nodes-page #dashboard-mesh-container .node-subtitle {
    font-size: 13px;
    color: #78909c;
}

#mesh-nodes-page #dashboard-mesh-container .node-subtitle.controller {
    color: var(--itb-green);
}

.mesh-nodes-table .node-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.mesh-nodes-table .node-title-container img,
.mesh-nodes-table .node-title-container svg {
    margin: 0 16px;
}

#mesh-nodes-page .mesh-gateway-mac {
    font-size: 13px;
    color: #78909c;
}

#dashboard-mesh-container {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    max-width: var(--max-width-box);
    padding-bottom: 40px
}

.mesh-nodes-table {
    overflow:auto;
    margin: 0 auto;
}

.mesh-nodes-table .list {
    margin: 20px auto;
    padding-bottom: 25px;
}

#dashboard-mesh-container .dashboard-subtitle.mesh {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

#dashboard-mesh-container .mesh-wps-disabled {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
}

#dashboard-mesh-container .mesh-wps-disabled a {
    text-decoration: none;
    color: cornflowerblue;
}

.mesh-timer {
    color: var(--itb-green);
    font-size: 20px;
    border: 2px solid var(--itb-green);
    border-radius: 5px;
    padding: 13px;
    width: 80px
}

.mesh-timer-info {
    color: var(--itb-green);
    font-size: 20px;
    border: 2px solid var(--itb-green);
    border-radius: 5px;
    padding: 13px;
    width: fit-content
}

.mesh-timer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.mesh-list-icon, .mesh-list-icon-offline {
    fill: darkgray;
    width: 40px;
    height: 40px;
}

.mesh-list-icon-offline {
    opacity: 0.5;
}

#mesh-controller-empty-dashboard {
    fill: darkgray;
    width: 88px;
    height: 88px;
}