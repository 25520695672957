#update-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
}

#update-page .subtitle {
    text-align: center;
}

#system-update-remote {
    height: 360px;
}

#update-diagram {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    max-width: var(--max-width-box);
    padding: 20px 0;
}

#update-diagram.status-only {
    position: relative;
    max-width: 200px;
}

#update-diagram.status-only .status-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 50px;
    animation: tada 500ms linear;
}

@keyframes tada {
    0% {
        width: 0px;
    }

    60% {
        width: 70px
    }

    100% {
        width: 50px;
    }
}

.update-dot {
    width: 12px;
    height: 12px;
    background-color: var(--light-gray);
    border-radius: 50%;
    transition: background-color 350ms ease-in-out;
}

.update-dot.active {
    background-color: var(--light-green);
    transition: background-color 350ms ease-in-out;
}

.update-status-info {
    padding: 10px 0;
    font-size: 18px;
    font-weight: bold;
}

.update-button {
    width: 400px;
}

#update-progress {
    margin: 10px 0;
    width: 480px;
    margin: 0 auto;
}

#update-progress span {
    position: relative;
    top: 10px;
}

#create-upgrade-file {
    margin: 0 auto;
    max-width: 400px;
}

#update-form {
    padding: 20px 0 0 0;
    margin: 0 auto;
    max-width: 400px;
}

.update-selector {
    width: 100%;
    height: 60px;
    padding: 12px;
    cursor: pointer;
    margin: 0;
}

.update-selector svg {
    fill: white;
    margin: 0 10px;
    cursor: pointer;
}

.update-selector label span {
    color: white;
    cursor: pointer;
}

.update-selector label {
    position: absolute;
    top: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 2px;
    margin: 0 10px;
    background-color: var(--itb-green);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 60px;
    border-radius: 5px;
}

.update-selector input {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    cursor: pointer !important;
}

#file-upload {
    cursor: pointer;
}