#firewall-port-forward-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#firewall-port-forward-page .info-card {
    max-width: 100%;
}

#firewall-port-forward-table{
    margin: 20px auto;
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
}