#advanced-qos-traffic-control-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
    overflow-x: auto;
}

#advanced-qos-traffic-control-page .list {
    min-width: 1220px;
}

.advanced-qos-traffic-control-list-wrapper {
    max-width: 100%;
    overflow-x: auto;
}

#advanced-qos-traffic-control-page .info-card {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
}

#advanced-qos-traffic-control-form {
    max-width: 450px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

#edit-qos-traffic-control-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    position: relative;
    margin: 0 auto;
    padding-bottom: 40px;
}

#edit-qos-traffic-control-page form {
    margin: 0 auto;
    padding-top: 15px;
}

#edit-qos-traffic-control-page .card {
    margin-top: 1em;
}


#edit-qos-traffic-control-page .itb-button-container {
    margin-top: 10px;
}

.qos_traffic_control_d_flex {
    display: flex;
}

#qos-tf-no-rules .info-card {
    max-width: 50%;
}

#edit-qos-traffic-control-page .itb-input-field {
    height: 85px;
}