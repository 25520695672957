:root {
    --width-box-small: 500px;
    --width-box-medium: 800px;
    --width-box-large: 1200px;
    --max-width-box: 90%;
}

.itb-button-container {
    max-width: 250px;
    margin: 0 auto;
}

input.itb-input:read-only + label {
    top: -2px;
    color: var(--itb-green);
    font-size: 12px;
}

input.itb-input:read-only {
    background-color: #f4f6f7;
}

.info-card-line:hover svg {
    fill: var(--gray) !important;
    animation: none !important;
}

.card-check-icon svg {
    fill: lime;
}

.scroll-list {
    overflow-x: auto;
}

.scroll-list .list {
    min-width: 700px;
}

.row {
    display: flex;
    flex-direction: row;
}

.mt2 {
    margin-top: 2em;
}

.row {
    display: flex;
    flex-direction: row;
}

.tabs-container {
    overflow-x: auto;
    max-width: 1200px !important;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .tabs-container {
        overflow-y: scroll;
    }
}

.lowercase {
    text-transform: lowercase;
}

.uppercase {
    text-transform: uppercase;
}

.align-center {
    text-align: center;
}

.long-text-field {
    word-break: break-word;
}

.card {
    padding: 10px;
    /* border: 1px solid #ddd; */
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 6px;
}

.itb-button-container {
    text-align: center;
    max-width: 250px;
    margin: 0 auto;
}

.itb-input-field label {
    z-index: 0 !important;
}

.with-info {
    position: relative;
}

.with-info .info-popup {
    position: absolute;
    top: -2px;
    right: 12px;
}

@media screen and (min-width: 500px) {
    #with-info .info-popup {
        left: 250px;
    }
}

#menu-system-backup-restore,
#menu-system-user {
    height: 120px;
}

.intelbras-logo-message {
    color: #fff;
    font-weight: bold;
    font-family: "SF Pro", "Roboto", "Arial", "Helvetica", sans-serif;
    text-align: right;
    padding: 20px;
    position: absolute;
    top: 100px;
    width: 100%;
    font-size: 18px;
    text-shadow:
        0 0 7px #fff,
}

.logo-background {
    background-color: #009325;
}

.noelements-card,
#no-wans-message {
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
}