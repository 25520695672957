#advanced-bridging-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

.advanced-bridging-list-wrapper {
    overflow-x: auto;
}

.advanced-bridging-list-wrapper .list {
    min-width: 675px;
}

.advanced-bridging-control {
    overflow-x: auto;
    margin: 0 auto;
}

#button-save-advanced-bridging{
    max-width: 100px;
}

#advanced-bridging-page .info-card {
    max-width: 100%;
}

#advanced-bridging-page .card {
    margin-bottom: 20px;
    text-align: left;
}