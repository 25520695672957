.title {
    font-size: 19px;
    margin-top: 24px;
    margin-bottom: 24px;
    font-weight: bold;
}

.item {
    display: flex;
    margin-top: 4%;
    align-items: baseline;
}

.content-item {
    font-size: 17px;
    color: gray;
    text-align: left;
    flex: 1;
}

.number-item {
    width: 32px;
    height: 32px;
    font-weight: bold;
    margin: 0 16px 16px 0;
    padding: 6px;
    border-radius: 15px;
    border: solid 1px var(--itb-green);
}