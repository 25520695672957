#firewall-wlan-access-control-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#firewall-wlan-access-control-page .list {
    min-width: 400px;

    margin-left: 0;
    margin-right: 0;
}

.firewall-wlan-access-control-list-wrapper {
    max-width: 100%;
    overflow-x: auto;
    
    /* margin: 25px; */
}

#firewall-wlan-access-control-page .info-card {
    max-width: 100%;
}

#firewall-wlan-access-control-no-elements .info-card {
    max-width: 50%;
    margin-top: 25px;
}

#firewall-wlan-access-control-form {
    max-width: 450px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

.firewall-wlan-access-control-warning {
    color: orange;
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
}