#service-upnp-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
}

#upnp-interfaces-info {
    color: orange;
    font-size: 0.79em;
    text-align: center;
}
