#dhcp-active-clients-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
}

.dhcp-active-clients-list-wrapper {
    overflow-x: auto;
    margin: 10px auto;
}

.dhcp-active-clients-list-wrapper .list {
    width: 900px;
    margin: 0 auto;
}

#dhcp-active-clients-page .info-card {
    max-width: 100%;
}

#dhcp-active-clients-form {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

#dhcp_client-no-rules {
    margin: 25px;
}