.edit-ipv6-ip-port-filter-page, #ipv6-ip-port-filter-page {
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
    margin: 0 auto;
    padding-bottom: 40px;
}

.ipv6-ip-port-filter-list-wrapper {
    overflow-x: auto;
    margin: 0 auto;
    margin-top: -10px;
    max-width: 1300px;
}

#ipv6-ip-port-filter-page .info-card {
    max-width: 100%;
}

#ipv6-ip-port-filter-page .card {
    max-width: 500px;
    margin: 30px auto;
}

.ipv6_ip_port_filtering_d_flex {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    display: flex;
}

.ipv6-ip-port-filter-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}

.ipv6-ip-port-filter-info a {
    text-decoration: none;
    color: cornflowerblue;
}

#ipv6-ip-port-filter-page-no-rules .info-card {
    max-width: 50%;
}

.edit-ipv6-ip-port-filter-page .itb-input-field {
    height: 90px;
}