.pager {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    margin: 0 auto;
    height: 50px;
}

.pager-changer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    width: 35px;
}

.pager-changer svg {
    fill: var(--itb-green);
}

.pager-page-number {
    font-size: 16px;
    padding: 8px 10px;
    background-color: var(--light-gray);
    border-radius: 6px;
    user-select: none;
    white-space: nowrap;
}

.pager-container {
    border-top: 1px solid var(--light-gray);
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
