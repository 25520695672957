#neighbour-hosts-page {
    width: var(--width-box-medium);
    max-width: var(--max-width-box);
    margin: 0 auto;
}

#neighbour-hosts-page .neighbour-hosts-list-wrapper{
    margin: 25px 0;
    overflow-x: auto;
}

#neighbour-hosts-page .list{
    width: var(--width-box-medium);
    margin: 0 auto;
}

#neighbour-hosts-page .info-card {
    max-width: 100%;
}
