#voip-proxy-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#voip-proxy-page form {
    margin: 0 auto;
    margin-top: 25px;
}

#voip-proxy-page .info-card {
    margin: 0 auto;
    margin-top: 32px;
}

.voip-proxy-section {
    width: 50%;
    display: inline-block;
}

.card-voip-edit-icon {
    text-decoration: underline;
}

.card-voip-edit-icon:hover {
    cursor: pointer;
}

.card-voip-edit-icon svg {
    fill: var(--gray);
}
