.tooltiptext {
    display: none;
    position: absolute;
    bottom: 30px;
    right: 0;
    text-align: left;
    padding: 10px;
    border-radius: 6px;
    box-shadow: var(--default-shadow);
    background-color: rgba(0, 0, 0, .7);
    color: white;
    line-height: 1.4rem;
}

.tooltip:hover .tooltiptext {
    display: block;
}