#firewall-mac-filtering-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#firewall-mac-filtering-page .info-card {
    max-width: 100%;
}

#firewall-mac-filtering-table{
    margin: 20px auto;
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
}

#firewall-mac-filtering-page strong {
    color: orange;
    font-size: 0.75em;
    display: block;
    margin: 0 auto;
    text-align: center;
}
