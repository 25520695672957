.wifi-page {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 30px;
    max-width: 800px;
    margin: 0 auto;
}

.wifi-available-title {
    width: 100%;
    text-align: left;
    vertical-align: middle;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px 15px 20px;
    margin: 0 auto;
}



.wifi-available-title svg {
    fill: var(--text-color)
}

.wifi-available-title span {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
    position: relative;
    top: 1px;
}


.wifi-status-title {
    width: 100%;
    text-align: left;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 15px 20px;
    margin: 0 auto;
}

.wifi-status-title svg {
    fill: var(--text-color)
}

.wifi-status-title span {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
    position: relative;
    top: 1px;
}

.wifi-edit-title {
    width: 100%;
    text-align: left;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 15px 20px;
    margin: 0 auto;
}

.wifi-edit-title svg {
    fill: var(--text-color)
}

.wifi-edit-title span {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
    position: relative;
    top: 1px;
}

.wifi-list {
    padding: 0px 0 30px 0;
    width: var(--width-box-small);
    max-width: var(--max-width-box);
    margin: 0 auto;
    /* background-color: white;
    border-radius: 6px;
    box-shadow: var(--default-shadow); */
}

.wifi-aps-count {
    width: 50%;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0 30px 0;
    margin: 0 auto;
}

.wifi-aps-count span {
    font-size: 12px;
    margin: auto;
    position: relative;
    top: 1px;
}

.wifi-item {
    display: flex;
    align-items: center;
    padding: 20px 0 0 0;
}
.wifi-item .toggle-group {
    margin-left: 10px;
}
.wifi-info {
    position: relative;
    /* margin-left: 20px ; */
    border-bottom: 2px solid var(--light-gray);
    padding: 0 10px; 
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wifi-info .wifi-frequency {
    position: absolute;
    bottom: 4px;
    left: 10px;
    font-size: 11px;
    color: var(--itb-green);
}

.wifi-info .tooltip {
    display: inline;
}

.wifi-info .tooltiptext {
    width: auto;
    margin-bottom: 20px;
}

#wifi-actions-container {
    display: flex;
    overflow: auto;
    width: var(--width-box-medium);
    max-width: var(--max-width-box);
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-around;
}

.wifi-action {
    height: 100px;
    padding: 10px;
    padding-left: 20px;
    margin: 10px;
    width: 130px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: white;
    position: relative;
}
.wifi-action span {
    display: block;
    text-align: left;
    font-size: 14px;
    width: 90px;
}
.wifi-action-icon {
    width: 100%;
    position: relative;
    left: -20px;
    margin-bottom: 15px;
    text-align: left;
    padding-left: 20px;
}
.wifi-action-icon.blue {
    border-left: 2px solid blue;
}
.wifi-action-icon.orange {
    border-left: 2px solid orange;
    position: relative;
}
.wifi-action-icon.orange:after {
    content: '+';
    color: orange;
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    top: 2px;
    left: 33px;
    border: 1px solid orange;
    border-radius: 50%;
    width: 10px;
    height: 12px;
    line-height: 12px;
    padding-left: 2px;
    background-color: white;
}
.wifi-action.disabled *, .wifi-action.disabled .wifi-action-icon.orange:after {
    color: lightgray !important;
    fill: lightgray;
    border-color:lightgray;
}
.wifi-action.disabled {
    cursor: not-allowed;
}

.wifi-wps-icon {
    width: 100%;
    position: relative;
    left: -20px;
    margin-bottom: 15px;
    text-align: left;
    padding-left: 20px;
}
.wifi-wps-icon.green {
    border-left: 2px solid var(--itb-green);
    position: relative;
}
.wifi-wps-icon svg {
    fill:  var(--itb-green);
}
.wifi-wps-icon.syncing svg {
    animation-name: ckw;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    fill:  var(--itb-green);
}

@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.wifi-ssid {
    color: #555;
    white-space: pre;
}

/* @media only screen and (min-width:500px){
    .wifi-list{
        max-width: 630px;
        padding: 0px 50px 40px;
    }
    .wifi-action{
        max-width: 550px;
    }
    #edit-wifi-form {
        padding: 20px 90px;
    }
} */

#edit-wifi-page {
    position: relative;
    width: var(--width-box-small);
    max-width: var(--max-width-box);
    margin: 0 auto;
    padding-bottom: 40px;
}

#edit-wifi-page form {
    max-width: 450px;
    margin: 0 auto;
}

.radio-page {
    padding: 0 20px;
    width: 90%;
    margin: 0 auto;
}

#radio-form {
    margin: 0 auto;
}

.wifi-ssid-action-icon {
    fill: var(--gray);
    padding: 5px;
    cursor: pointer;
    transition: fill 200ms ease-in-out 
}

.wifi-ssid-action-icon.edit:hover {
    fill: var(--light-green)
}

.wifi-ssid-action-icon.delete:hover {
    fill: var(--error-color)
}

.wifi-ssid-action-icon {
    display: inline;
}

#wifi-frequency-radios {
    display: flex;
    justify-content: space-around;
    max-width: 300px;
    margin: 0 auto;
}

.wifi-item .loading {
    height: 20px;
    padding: 18px;
    margin-left: 10px;
}

.wifi-item .loading:after {
    height: 20px;
    width: 20px;
}

#wifi-create-button span {
    white-space: nowrap;
}

#radio-form .radio-current-channel {
    text-align: left;
    padding: 0 0 5px 10px;
}

#wireless-cipher .itb-input-field{
    width: 90%;
}

#wireless-cipher .info-popup {
    top: 25%;
}

.radio-boxes-container {
    margin: 0 auto;
    display: flex;
    padding-right: 10px;
    max-width: 800px;
}

.radio-boxes-container .card{
    width: 300px;
    max-width: 90%;
    margin: 20px auto;
}

@media screen and (max-width: 768px) {
    .radio-boxes-container {
        display: block;
    }

    .wifi-page .collapse-box {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 5%;
    }
}

#edit-wifi-status-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
}

#info-card-wifi-status {
    overflow: auto;
    margin: 0 auto;
    max-width: var(--max-width-box);
}

#info-card-wifi-status .list {
    margin-bottom: 20px;
}

.network-wifi-status-5g {
    overflow: auto;
    width: 1000px;
    white-space: nowrap;
    margin: 0 auto;
}

#info-card-wifi-status-vap {
    width: var(--width-box-large);
    max-width: var(--max-width-box);
    margin: 0 auto;
}

.network-wifi-status-vap {
    overflow-x: auto;
}

.network-wifi-status-vap .list {
    width: 900px;
    overflow-x: auto;
    margin: 0 auto;
}



#edit-wifi-status-page .row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
}

#back-to-radio5ghz-btn{
    max-width: 250px;
}

#back-to-radio24ghz-btn{
    max-width: 250px;
}

#back-to-wifi-btn {
    width: 250px;
}
#refresh-btn {
    width: 250px;
    margin: 0 auto;
}

#wifi-connected-devices-page {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    overflow-x: auto;
}

#wifi-connected-devices-page .list {
    min-width: 900px;
}

.wifi-connected-devices-list-wrapper {
    max-width: 100%;
    overflow-x: auto;
    margin: 0px;
}

.wifi-connected-devices-list-wrapper .list {
    width: 850px;
    margin: 20px auto;
}

#wifi-connected-devices-page .info-card {
    max-width: 100%;
}

#wifi-connected-devices-form {
    max-width: 450px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

#wifi-connected-devices-page .row {
    display: flex;
    align-items: center;
    justify-content: center;
}

#info-card-connected-devices-list {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 40px;
}

#back-to-wifi-list {
    margin-top: 25px;
}

.ieee_std_checkboxes {
    display: flex;
    justify-content: space-evenly;
}

.radio-page strong{
    color: orange;
    font-size: 0.75em;
    display: block;
    margin: 0 auto;
    text-align: center;
}