#pon-status-page {
    margin: 0 auto;
}

#pon-status-page .info-card {
    width: var(--width-box-small);
    max-width: var(--max-width-box);
    margin: 30px auto;
}

.pon-status-mode-label {
    text-transform: uppercase;
}
