#backup-restore-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#system-backup-restore-remote {
    height: 360px;
}

#backup-restore-diagram {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    max-width: 500px;
    padding: 20px 0;
}

#backup-restore-diagram.status-only {
    position: relative;
    max-width: 200px;
}

#backup-restore-diagram.status-only .status-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 50px;
    animation: tada 500ms linear;
}

@keyframes tada {
    0% {
        width: 0px;
    }

    60% {
        width: 70px
    }

    100% {
        width: 50px;
    }
}

.backup-restore-dot {
    width: 12px;
    height: 12px;
    background-color: var(--light-gray);
    border-radius: 50%;
    transition: background-color 350ms ease-in-out;
}

.backup-restore-dot.active {
    background-color: var(--light-green);
    transition: background-color 350ms ease-in-out;
}

.backup-restore-status-info {
    padding: 10px 0;
    font-size: 18px;
    font-weight: bold;
}

.backup-restore-button {
    width: 400px;
}

#backup-restore-progress {
    margin: 10px 0;
    width: 480px;
    margin: 0 auto;
}

#backup-restore-progress span {
    position: relative;
    top: 10px;
}

#backup-restore-form {
    padding: 20px 0 0 0;
    margin: 0 auto;
    max-width: 400px;
}

.backup-restore-selector {
    width: 100%;
    height: 50px;
    padding: 12px;
    cursor: pointer;
    margin: 0;
}

.backup-restore-selector svg {
    fill: white;
    margin: 0 10px;
    cursor: pointer;
}

.backup-restore-selector label span {
    color: white;
    cursor: pointer;
}

.backup-restore-selector label {
    /* width: 300px; */
    position: absolute;
    top: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 2px;
    margin: 0 10px;
    background-color: var(--itb-green);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 60px;
    border-radius: 5px;
}

.backup-restore-selector input {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    cursor: pointer !important;
}

#file-upload {
    cursor: pointer;
}

.factory-default-info,.custom-default-config-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}