.checkbox-group {
    display: flex;
    position: relative;
    text-align: left;
    margin: 20px 0 30px 0;
}

.checkbox-group input[type=checkbox] {
    opacity: 0;
}

.checkbox-group .after-input {
    padding-left: 32px;
    color: var(--text-color);
    font-size: 16px;
    cursor: pointer;
    min-height: 17px;
}

.checkbox-group .after-input:after, .checkbox-group .after-input:before {
    position: absolute;
}

.checkbox-group .after-input::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid #cfd8dc;
    left: 10px;
    top: -1px;
    font-size: 15px;
}

.checkbox-group .after-input:after {
    content: "";
    display: inline-block;
    height: 6px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: white;
    transform: rotate(-45deg);
    left: 16px;
    top: 3px;
}

.checkbox-group input[type=checkbox]:checked + .after-input:after {
    content: '';
}
.checkbox-group input[type=checkbox]:checked + .after-input:before {
    content: '';
    background-color: var(--itb-green);
    border-color: var(--itb-green);
}

.checkbox-group input[type=checkbox] + .after-input:after {
    content: none;
}

.checkbox-group.disabled * {
    cursor: not-allowed;
}
.checkbox-group.disabled *, .checkbox-group.disabled .after-input::before, .checkbox-group.disabled input[type=radio]:checked + .after-input:before {
    color: var(--medium-gray);
    fill: var(--medium-gray);
    border-color: var(--medium-gray);
}
.checkbox-group.disabled input[type=radio]:checked + .after-input:before {
    background-color: var(--medium-gray);
}

.checkbox-group.disabled input[type=checkbox]:checked + .after-input:before {
    background-color: var(--medium-gray);
    border-color: var(--medium-gray);
}