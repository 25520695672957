.flag {
    cursor: pointer;
    width: 48px;
    height: 48px;
    margin-top: 10px;
    margin-left: 5px;
    filter: grayscale(1);
}

.flag:hover {
    filter: grayscale(0.5);
}