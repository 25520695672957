#network-wan {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#network-wan-page {
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
    margin: 0 auto;
    padding-bottom: 40px;
}

#network-wan-edit-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#network-wan-edit-page form, #network-wan-page .info-card {
    min-width: 260px;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: center;
}

.card-network-wan-edit-icon:hover {
    cursor: pointer;
}

.card-network-wan-edit-icon svg {
    fill: var(--gray) !important;
}

.card-network-wan-edit-icon:hover svg {
    fill: lime !important;
}

.card-network-wan-delete-icon:hover {
    cursor: pointer;
}

.card-network-wan-delete-icon:hover svg {
    fill: red !important;
}

.card-network-wan-delete-icon  svg {
    fill: var(--gray) !important;
}

.default-gateway-error-wrapper {
    text-align: left;
}

.disabled-ports-warning {
    padding-bottom: 10px;
    color: orange;
    font-size: 0.75em;
}

.network-wan-mode-warning {
    color: orange;
    font-size: 0.75em;
    margin-bottom: 20px;
}

.network-wan-protocol-warning {
    color: orange;
    font-size: 0.75em;
    margin-bottom: 20px;
}

#network-wan-edit-page .network-wan-protocol-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}

#network-wan-edit-page .network-wan-protocol-info a {
    text-decoration: none;
    color: cornflowerblue;
}

.noelements-card {
    width: 100%;
    padding: 10px;
    margin: 0 auto;
}

.network-wan-ipv6-addr-mode {
    text-align: left;
    vertical-align: middle;
    display: flex;
    font-size: 12px;
    padding: 0 10px 20px 10px;
    justify-content: flex-start;
}

.network-wan-port-mapping {
    text-align: left;
    vertical-align: middle;
    font-size: 18px;
    padding: 12px 0 8px 0;
}

.network-wan-port-mapping-wrapper {
    width: 85%;
    margin: 0 auto;
}

.network-wan-port-mapping-wrapper .row {
    justify-content: space-between;
}

.network-wan-port-mapping-section {
    width: 100%;
}

.network-wans-box {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}

#network-wan-edit-page .itb-button-container {
    margin-top: 15px;
}