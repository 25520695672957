.flags-container {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    z-index: 3;
    top: 10px;
    width: auto;
}

.selected {
    filter: grayscale(0);
}

.selected:hover {
    filter: grayscale(0);
}