#ipv6-radvd-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#ipv6-radvd-page .ipv6-radvd-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}

#ipv6-radvd-page .ipv6-radvd-info a {
    text-decoration: none;
    color: cornflowerblue;
}