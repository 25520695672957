.parental-control-connected-device-page {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    /*overflow-x: auto; */
}

.parental-control-connected-device-page .info-text {
    text-align: center;
}

.parental-control-connected-device-page .itb-modal {
    overflow-y:auto;
}

.device-list-item {
    padding: 20px;
    border-radius: 6px;
    box-shadow: var(--darker-shadow);
    display: flex;
    align-items: center;
    margin: 10px auto;
    cursor: pointer;
}

.device-list-item * {
    cursor: pointer;
}

.device-list-item:hover {
    box-shadow: var(--green-shadow);
}

.device-list-item > .device-item-image {
    padding: 0 30px 0 20px;
}

.device-list-item > .device-item-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.device-item-info > .device-item-hostname {
    color: var(--dark-gray);
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    padding: 0 20px;
}

.device-item-info > .device-info {
    display: flex;
}

.device-list-item > .device-action {
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .device-item-info > .device-info {
        display: block;
    }
}

.parental-control-page > .qos-list-container > .container,
.parental-control-page > .device-block-container,
.parental-control-page > .filter-page-container > .container
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qos-list-container > .band-control-container {
    width: 100%;
}

.parental-control-page > .filter-page-container .scrollable {
    max-height: calc(100vh - 360px) !important;
}

.device-info-item {
    margin: 0 20px;
}

.device-info-item span{
    margin: 0 0.5rem;
}

.device-subtitle {
    text-align: left;
    font-size: 21px;
    margin: 24px 0;
    display: flex;
}

.device-subtitle label {
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.device-title {
    text-align: left;
    font-size: 21px;
    margin: 24px 0;
    display: flex;
}

.device-title label {
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-weight: bold;
}