#ping-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#ping-page #ping-form {
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

#ping-page .ping-result {
    max-width: var(--max-width-box);
    margin: 0 auto;
    text-align: center;
}

#ping-page .ping-result .info-text {
    padding: 0 5px;
    justify-content: center;
}

#ping-page .ping-result-details .dashboard-subtitle {
    padding: 0;
    justify-content: center;
    margin: 0;
}

#ping-page .ping-result-details .dashboard-subtitle .subtitle {
    text-align: left;
    margin: 0 1rem;
    align-content: start;
}

#ping-page .ping-result-details .dashboard-subtitle svg {
    margin: auto 0;
}


@media (min-width: 500px) {
    #ping-page .ping-result-details {
        display: flex;
        justify-content: center;
    }
}
