.collapse-box-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 0 8px 0;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    user-select: none;
}

.collapse-box-title {
    font-weight: bold;
    font-size: 18px;
    padding: 5px 0;
}

.collapse-arrow svg{
    transition: transform 200ms linear;
    position: relative;
    right: 4px;
}

.collapse-arrow.up svg{
    transform: rotate(-90deg);
}
.collapse-arrow.down svg {
    transform: rotate(90deg);
}

.collapse-box-content {
    transition: height 200ms linear;
    overflow: hidden;
    padding-top: 15px;
}

@media screen and (min-width: 768px) {
    .collapse-box{
        max-width: 600px;
        margin: 0 auto;
    }
}