.tabs-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.tab {
    flex: 1;
    text-align: center;
    padding: 15px;
    border-bottom: 3.7px solid var(--light-gray);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: var(--gray);
    user-select: none;
}
.tab:hover {
    background-color: var(--light-gray);
}

.tab.active {
    border-bottom: 5.5px solid var(--light-green);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    color: var(--dark-gray);
}

@media only screen and (min-width: 768px){
    .tabs-container {
        max-width: 1080px;
        margin: 0 auto;
    }
}

.tab.disable {
    cursor: not-allowed;
}