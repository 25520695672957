#loop-back-detect-settings-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#loop-back-detect-settings-page form {
    margin: 0 auto;
}

#loop-back-detect-vlan-table {
    margin: 20px auto;
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    /* margin: 0 auto; */
}

#loop-back-detect-settings-page strong,
#loop-back-detect-status-page strong {
    color: orange;
    font-size: 0.75em;
    display: block;
    margin: 0 auto;
    text-align: center;
}

#loop-back-detect-status-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#loop-back-detect-status-page form {
    margin: 0 auto;
}