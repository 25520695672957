#lan-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
    padding-bottom: 40px;
}
#lan-page-content {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.lan-buttons-div, .lan-dhcp-server-div {
    width: var(--width-box-small);
    margin: 0 auto;
}

#lan-page form {
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--default-shadow);
}

#dhcp-leases-button {
    position: relative;
    height: 78px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    box-shadow: var(--darker-shadow);
}

#dhcp-leases-button:hover .tooltiptext {
    display: block;
    bottom: 60px;
}

#dhcp-leases-button[tooltip="0"] .tooltiptext {
    display: none;
}

.lease-list-container {
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
    margin: 0 auto;
    padding-bottom: 40px;
}

.dhcp-lease-list-wrapper {
    overflow-x: auto;

}

.dhcp-lease-list-wrapper .list {
    min-width: 675px;
}

#back-to-lan-button {
    margin-top: 25px;
}

.lease-list-container .scrollable-wrapper {
    margin: 0 20px;
}

.lease-list-container .scrollable {
    max-height: calc(100vh - 340px) !important;
}

.lease-list-container .scrollable .list-item {
    width: 100%;
}

#add-dhcp-lease {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
}

.lease-list-container .scrollable.rules-list {
    overflow-x: hidden;
}

#dhcp-active-clients-button {
    position: relative;
    height: 78px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    box-shadow: var(--darker-shadow);
}

.lease-list-container .total-of-regs {
    margin-top: 20px;
    text-align: center;
}