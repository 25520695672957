#firewall-domain-blocking-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

.firewall-domain-blocking-table-wrapper {
    overflow-x: auto;
    margin: 0;
}

.firewall-domain-blocking-table-wrapper .list {
    margin: 0;
}

#firewall-domain-blocking-page strong {
    color: orange;
    font-size: 0.75em;
    display: block;
    margin: 0 auto;
    text-align: center;
}