#advanced-qos-classification-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
    overflow-x: auto;
    padding-bottom: 40px;
}

#advanced-qos-classification-page .itb-button-container {
    margin: 0 auto;
}

.advanced-qos-classification-list-wrapper {
    max-width: 100%;
    margin: 25px 0;
    overflow-x: auto;
}

.advanced-qos-classification-list-wrapper .list {
    min-width: var(--width-box-medium);
    margin: 0 auto;
}

#advanced-qos-classification-page .info-card {
    max-width: 100%;
}

#advanced-qos-classification-form {
    max-width: 450px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

.qos_classification_d_flex {
    display: flex;
}

#edit-qos-classification-page {
    width: var(--width-box-small);
    max-width: var(--max-width-box);
    margin: 0 auto;
    padding-bottom: 40px;
}

#edit-qos-classification-page .card {
    margin-top: 1rem;
}

#edit-qos-classification-page .itb-button-container {
    margin: 0 auto;
    margin-top: 10px;
}

#edit-qos-classification-page form {
    margin: 0 auto;
}

.details-modal-main-div .header-div {
    word-break: break-all;
    font-weight: bold;
}

.details-modal-main-div .details-div {
    width: 100%;
    min-width: 400px
}

#qos-cls-no-rules .info-card {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
}