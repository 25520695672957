#voip-codec-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#voip-codec-page form {
    min-width: 250px;
    margin: 0 auto;
    margin-top: 25px;
}

#voip-codec-page .info-card {
    margin: 0 auto;
    margin-top: 32px;
}

.active-codec-list {
    min-height: 100px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: auto;
    max-height: 200px;
    overflow-y: auto;
}

.active-codec-wrapper {
    min-height: 40px;
    margin: 5px;
    border: 1px dashed #ddd;
    border-radius: 5px;
}

.codec-lists-wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.codec-list-right {
    margin-top: 20px;
}

.codec-handle-controls {
    display: flex;
    flex-direction: row;
}

@media only screen and (min-width: 768px) {
    .codec-lists-wrapper {
        flex-direction: row;
    }

    .codec-list-left {
        width: calc(40% - 10px);
        margin-right: 10px;
    }

    .codec-list-right {
        width: 70%;
        margin-top: 0;
    }

    .active-codec-list {
        max-height: 600px;
        overflow-y: auto;
    }
}

.codecs-list {
    text-align: left;
}
