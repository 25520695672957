.itb-modal-container {
    position: relative;
    display: none;
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100vw;
    overflow-y: hidden;
}
.itb-modal-container.show {
    display: flex;
}

.itb-modal {
    z-index: 10;
    background-color: white;
    padding: 30px 20px 50px 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 20%;
    max-height: 100%;
    overflow-y: overlay;
    animation: FromBottom 200ms ease-out;
}
@media screen and (min-width: 500px) {
    .itb-modal{
        position: relative;
        margin: 0 20px;
        border-radius: 10px;
        width: fit-content;
        padding: 30px 20px 30px 20px;
        animation-duration: 100ms;
    }
}
@keyframes FromBottom {
    0% {
        bottom: -100%;
    }
    100% {
        bottom: 0;
    }
}
.itb-modal.has-title {
    padding-top: 50px;
}
.itb-modal.large {
    max-width: 800px;
}

.itb-modal-title {
    position: absolute;
    top: 15px;
    left: 20px;
    font-weight: bold;
    width: calc(100% - 40px);
    text-align: center;
    font-size: 19px;
}

.itb-modal-content {
    padding: 5px 10px 10px 10px;
    text-align: center;
    line-height: 1.8;
}

.itb-modal-content > div {
    max-width: 100%;
}

.itb-modal-content svg {
    margin-bottom: 10px;
}

.itb-modal-dismiss {
   display: flex;
   justify-content: center;
   margin: 20px auto 0px auto;
}

.itb-modal-dismiss.error {
    color: var(--error-color);
    border: 2px solid var(--error-color);
    max-width: 180px;
}

.dismiss-button{
    flex: 1;
    cursor: pointer;
    border: 2px solid var(--itb-green);
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    color: var(--itb-green);
    user-select: none;
    margin: 0 15px;
    padding: 15px;
    white-space: nowrap;
}

.dismiss-button-no, .dismiss-button-yes {
    cursor: pointer;
    text-align: center;
    max-width: 200px;
}
.dismiss-button-lonely {
    max-width: 160px;
}
.dismiss-button-yes {
    color: white;
    background-color: var(--itb-green);
}
.dismiss-button.error {
    color: var(--error-color);
    border-color: var(--error-color);
}
.dismiss-button-yes.warning {
    color: white;
    background-color: var(--error-color);
    border-color: var(--error-color);
}
.dismiss-button-no {
    border-color: var(--gray);
    color: var(--gray);
}

.dismiss-button-no:hover {
    background-color: var(--light-gray);
}
.dismiss-button-yes.warning:hover {
    background-color: rgba(205, 92, 92, .92);
}
.dismiss-button-yes:hover{
    background-color: rgba(0, 163,53, .85);
}
.dismiss-button-lonely:hover {
    background-color: rgba(0, 163,53, .05);
}
.dismiss-button-lonely.error:hover {
    background-color: rgba(205, 92, 92, .05);
}

.itb-modal-close {
    fill: var(--gray);
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    z-index: 4;
}
.itb-modal-close svg {
    position: absolute;
    right: 5px;
}

.itb-modal-mobile-dismiss {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fafafa;
    height: 35px;
    border: 1px solid #eee;
    box-shadow: -1px -4px 4px -4px #eee;
    cursor: pointer;
    z-index: 4;
}
.itb-modal-mobile-dismiss svg{
    transform: rotate(270deg);
    fill: var(--text-color);
    position: relative;
    top: 6px;
}
@media screen and (min-width: 500px){
    .itb-modal-mobile-dismiss {
        display: none;
    }
}
