.tabs-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.bullets-container {
    display: none;
    text-align: center;
}

.tab {
    flex: 1;
    text-align: center;
    padding: 15px;
    border-bottom: 3.7px solid var(--light-gray);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: var(--gray);
    user-select: none;
}
.tab:hover {
    background-color: var(--light-gray);
}

.tab.active {
    border-bottom: 5.5px solid var(--light-green);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    color: var(--dark-gray);
}

.bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--light-gray);
    display: inline-block;
    margin: 5px;
}

.bullet:hover, .bullet.active {
    background-color: var(--light-green);
}

@media only screen and (min-width: 768px){
    .tabs-container {
        max-width: 1080px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 768px){
    .tabs-container {
        overflow-y: scroll;
    }

    .bullets-container {
        display: block;
    }
}

.tab.disable {
    cursor: not-allowed;
}