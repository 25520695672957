#traceroute-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;  
    padding-bottom: 40px;
}

#traceroute-page #traceroute-form {
    margin: 0 auto;
    /* padding-top: 30px; */
    text-align: left;
}

#traceroute-page .traceroute-result {
    margin: 0 auto;
    text-align: center;
}

#traceroute-page .traceroute-result-details {
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.traceroute-results-list-wrapper {
    width: var(--width-box-small);
    overflow-x: auto;
}

#traceroute-page .results-table-subtitle {
    display: flex;
    justify-content: center;
}

#traceroute-page .results-table-subtitle svg {
    margin: auto 0;
    margin-right: 1rem;
}