.traffic-indicator {
    text-align: center;
}

.traffic-indicator.horizontal {
    display: flex;
    align-items: center;
    width: 110px;
}

.traffic-indicator.horizontal .traffic-indicator-text {
    padding-left: 5px;
}
.traffic-indicator.horizontal .traffic-indicator-label {
    text-align: left !important;
}

.traffic-indicator-value {
    font-size: 25px;
    padding: 10px 0;
}
.traffic-indicator-unit {
    display: block;
    font-size: 17px;
    color: #92a4b1;
    padding: 10px 0;
}

.traffic-indicator-label {
    font-size: 17px;
    color: #859aa7;
    margin-left: 8px;
}

.traffic-indicator-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.traffic-indicator-icon.upload svg {
    transform: rotate(180deg)
}

.traffic-indicator-icon svg {
    fill: #00ac34;
}

@keyframes traffic-pulse {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
