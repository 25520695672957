#firewall-ip-port-filtering-page {
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
    margin: 0 auto;
    padding-bottom: 40px;
}

#firewall-ip-port-filtering-page .info-card {
    max-width: 100%;
}

.larger-field-wrapper .itb-input-field {
    height: 85px;
}

.firewall-filter-group {
    display: flex;
    width: 100%;
}

.firewall-filter-sub-group {
    width: 100%;
    display: flex;
}

@media only screen and (max-width: 700px) {
    .firewall-filter-group {
        flex-direction: column;
    }
}

#firewall-ip-port-filtering-table{
    margin: 20px auto;
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
}