#parental-control-parental-control-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
    overflow-x: auto;
    padding-bottom: 40px;
}

.parental-control-parental-control-list-wrapper .list {
    min-width: 1180px;
    margin: 0 auto;
}

.parental-control-parental-control-list-wrapper {
    max-width: 100%;
    overflow-x: auto;
    margin: 25px 0;
}

#parental-control-parental-control-page .info-card {
    max-width: 100%;
}

#parental-control-parental-control-form {
    max-width: 450px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

#parental-control-parental-control-edit-page {
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

.parental-control-d-flex {
    display: flex;
}

.parental-control-days-box {
    display: flex;
    justify-content: space-between;
}

.parental-control-day-element {
    display: inline-block;
}

.parental-control-day-txt {
    display: block;
    text-align: center;
    margin-left: -5px;
}

#parental-control-no-rules .info-card {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
}

.parental-control-days-in-table {
    display: inline;
}

.parental-control-days-in-table strong{
    color: var(--itb-green);
}

.parental-control-rules-num-warning {
    color: orange;
    font-size: 0.75em;
    display: flex;
}

.parental-control-rules-num-warning strong{
    margin: 0 auto;
}

.parental-control-enable{
    width: var(--width-box-small);
    margin: 0 auto;
}

.parental-control-buttons {
    display: flex;
    width: 400px;
    margin: 0 auto;
}

.parental-control-buttons .itb-button-md {
    min-width: 100px;
}