:root {
    --itb-green: #00a335;
    --light-green: #4cc968;
    
    --error-color: indianred;

    --dark-gray: #555;
    --gray: #a5a5a5;
    --medium-gray: #e2e2e2;
    --light-gray: #f2f2f2;

    --text-color: #191c23;
    --text-color-dark: #4a4a4a;

    --main-gradient: linear-gradient(to bottom, #87c984, #00a335);
    --red-gradient: linear-gradient(to bottom, tomato, rgb(240, 80, 60));
    --red-bright-gradient: linear-gradient(to bottom, rgb(250, 80, 60), tomato);

    --reverse-main-gradient: linear-gradient(to top, #87c984, #00a335);

    --gray-gradient: linear-gradient(to top, #ddd, #bbb);

    --dark-main-gradient: linear-gradient(to bottom, #77b974, #009325);
    --light-main-gradient: linear-gradient(to bottom, #97d994, #10b345);

    --main-header-size: 115px;
    --main-header-top-size: 70px;
    --main-header-extended-content-size: 306px;
    --main-header-size-extended: calc(var(--main-header-top-size) + var(--main-header-extended-content-size));
    --main-header-size-collapsed: 52px;

    --default-shadow: 0 2px 10px 0 rgba(0, 0, 0, .12);
    --darker-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
    --green-shadow: 0 2px 10px 0 rgba(0, 50, 0, .2);
}
