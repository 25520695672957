#mesh-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
}

#mesh-type.with-info .info-popup {
    right: 10px;
    left: auto;
    position: absolute;
    top: -10px;
}

#mesh-page .mesh-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}

#mesh-page .mesh-info a {
    text-decoration: none;
    color: cornflowerblue;
}

#mesh-explication-text {
    font-size: 13px;
    margin-bottom: 30px;
}