.info-card {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin: 10px;
    max-width: 650px;
}

.info-card-title span {
    white-space: nowrap;
    margin-left: 10px;
    position: absolute;
    top: -9px;
}

.info-card-line {
    padding: 8px 0 8px 12px !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.info-card-line.odd {
    background-color: var(--light-gray);
}

@media screen and (min-width: 500px){
    .info-card-line {
        flex-direction: row;
        align-items: center;
        height: 40px;
    }
}

.info-card-line.large {
    height: unset !important;
}

.info-card-line * {
    flex: 1;
}
.info-card-line label {
    white-space: nowrap;
    text-align: left;
    padding:5px 20px 5px 5px;
    font-weight: normal !important;
}
.info-card-line span {
    font-weight: bold;
    padding-left: 20px;
    text-align: right;
    white-space: nowrap;
}
.info-card-line-icon {
    min-width: 35px;
    max-width: 35px;
    text-align: center;
    fill: #555;
    padding-left: 5px;
    position: absolute;
    right: 10px;
}
@media screen and (min-width: 500px) {
    .info-card-line-icon {
        position: relative;
        right: 0;
    }
}

.info-card-line span.warning {
    color: var(--error-color);
}

.info-card-line-icon svg {
    width: 18px;
    fill: #555;
}
.info-card-line:hover svg {
    width: 22px;
    fill: lime;
    animation: attention 250ms ease-in-out;
}

@keyframes attention {
    0% {
        width: 18px;
        fill: #555;
    }
    50% {
        width: 28px;
        fill:lime;
    }
    100% {
        width: 22px;
        fill: lime;
    }
}

.info-card-line-icon > .info-popup {
    position: relative;
    top: 0;
    left: 0;
}

.info-card-line:hover .info-popup > svg {
    width: 18px;
    fill: #555;
    animation: none;
}