.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 400px;
    padding: 20px 0 40px 0;
    position: relative;
}

.loading:after{
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid var(--itb-green);
    animation: spin 1s infinite linear;
    border-color: var(--itb-green) transparent var(--itb-green) transparent;
}

.small {
    padding: 10px 0 20px 0;
}
.loading.small:after{
    width: 30px;
    height: 30px;
    border: 2px solid var(--itb-green);
    border-color: var(--itb-green) transparent var(--itb-green) transparent;
}

.mini {
    padding: 5px 0 10px 0;
}
.loading.mini:after{
    width: 15px;
    height: 15px;
    border: 1px solid var(--itb-green);
    border-color: var(--itb-green) transparent var(--itb-green) transparent;
}
@keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}