.itb-button-container {
    width: 100%;
    padding: 10px;
}

.itb-button {
    padding: 10px;
    background-color: var(--itb-green);
    color: white;
    border: none;
    border-radius: 4px;
    height: 40px;
    outline: none;
    transition: background-color 200ms;
    margin: 5px 0;
    cursor: pointer;
}
.itb-button:hover {
    font-size: 102%;
    font-weight: bold;
}
.itb-button:active {
    background-color: #009430;
}
.itb-button:disabled {
    cursor: not-allowed;
    background-color: var(--gray) !important;
    color: var(--light-gray);
}
.itb-button.outlined {
    background-color: transparent;
    color: var(--itb-green);
    border: 1px solid var(--itb-green);
}
.itb-button.outlined:disabled {
    background-color: transparent !important;
    color: var(--gray);
    border: 1px solid var(--gray);
}

.itb-button-red {
    background-color: var(--error-color);
}

.itb-button.outlined.itb-button-red {
    background-color: transparent;
    color: #e02020;
    border: 1px solid #e02020;
}

.itb-button-red:active {
    background-color: #C34B57;
}

.itb-button-md {
    width: 100%;
    min-width: 200px;
    max-width: 450px;
}

.itb-button-circular {
    --size:50px;
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border-radius: 100%;
    font-size: 35px;
    line-height: 30px;
    vertical-align: middle;
}

.itb-button-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100px;
    /* padding: 5px 0 20px 0; */
    position: relative;
}
.itb-button-loading:after{
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid var(--itb-green);
    animation: spin 1s infinite linear;
    border-color: var(--itb-green) transparent var(--itb-green) transparent;
}
@keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

