#dashboard-page {
    /* position: relative; */
    padding: 10px 20px;
    margin: 0 auto;
    width: 100%;
    /* height: calc(100vh - 376px); */
    max-width: 1300px;
    overflow-x: auto;
}

@media screen and (max-width: 460px){
    #dashboard-page {
        padding: 40px 0;
    }
}

@media screen and (min-width: 1080px) {
    #dashboard-page {
        padding: 15px 0;
    }
}

#dashboard-content {
    text-align: center;
    margin:0 auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    min-width: 350px;
}


.dashboard-model {
    fill: var(--gray);
    color: #555;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

.dashboard-model-content {
    align-items: center;
    display: flex;
    padding-left: 35px;
}
.dashboard-model-content span {
    padding: 1px 0 0 10px;
}

.dashboard-buttons {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    flex-wrap: wrap;
    max-width: 90vw;
    flex-basis: fit-content;
}

@media screen and (max-width: 460px){
    .dashboard-buttons {
        width: 100vw;
        padding: 0;
        max-width: 100%;
    }
}

#dashboard-details-container {
    width: 100%;
}

.dashboard-details {
    flex: 1;
}

.dashboard-subtitle {
    text-align: left;
    font-size: 21px;
    margin: 24px 0;
    display: flex;
}

.dashboard-subtitle label {
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.dashboard-subtitle-modal {
    text-align: left;
    font-size: 21px;
    margin: 0 0;
    display: flex;
}

.dashboard-subtitle-modal label {
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.dashboard-subtitle svg {
    margin-right: 5px;
}

.new-version-modal {
    max-width: 430px;
}

.info-card-line span {
    padding-left: 5px !important;
}

.dashboard-details.device .info-card-line label {
    min-width: 200px;
    max-width: 200px;
}

.dashboard-link {
    color: var(--itb-green);
    cursor: pointer;
    text-decoration: underline;
    align-items: center;
    text-align: center;
}

#dashboard-page .list {
    margin: 20px 0;
}

#dashboard-page .dashboard-subtitle.mesh {
    display: flex;
    justify-content: space-between;
}

#dashboard-page .dashboard-subtitle.mesh .btn-add-mesh-node {
    display: flex;
    border: solid 1px #dfe2e6;
    border-radius: 3px;
    height: 40px;
    width: 243px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 32px;
    cursor: pointer;
}

#dashboard-page .dashboard-subtitle.mesh .btn-add-mesh-node * {
    cursor: pointer;
}

#dashboard-page .dashboard-subtitle.mesh .btn-add-mesh-node label {
    font-size: 17px;
    font-weight: 600;
}

#dashboard-page .dashboard-subtitle.mesh .btn-add-mesh-node:hover {
    border-color: var(--gray);
}

#dashboard-page .mesh-device-signal {
    display: flex;
    align-items: center;
}

#dashboard-page .mesh-device-signal > span {
    padding: 10px;
}

#dashboard-page .mesh-no-device-title {
    margin: 8px auto;
    font-size: 19px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text-color);
}

#dashboard-page .mesh-no-device-subtitle {
    margin: 8px auto;
    line-height: 1.4;
    text-align: center;
    color: #a9b6c3;
}

#dashboard-page #dashboard-mesh-container .node-title {
    font-size: 17px;
}

#dashboard-page #dashboard-mesh-container .node-subtitle {
    font-size: 13px;
    color: #78909c;
}

#dashboard-page #dashboard-mesh-container .node-subtitle.controller {
    color: var(--itb-green);
}

#dashboard-page #dashboard-mesh-container .node-title-container {
    display: flex;
}

#dashboard-page #dashboard-mesh-container .node-title-container img,
#dashboard-page #dashboard-mesh-container .node-title-container svg {
    margin: 0 16px;
}

#dashboard-page .mesh-gateway-mac {
    font-size: 13px;
    color: #78909c;
}

.dashboard-list-wrapper  {
    max-width: 1200px;
    overflow-x: auto;
    margin: 0 auto;
}

#dashboard-page .list {
    min-width: 700px;
}

#dashboard-page .card-button {
    width: 225px;
}

@media screen and (max-width: 600px) {
    #dashboard-page .card-button {
        width: 150px;
        height: 200px;
    }
    
    #dashboard-page .card-button-value {
        margin-top: 30px;
    }
}

#dashboard-page #card-networks-channels svg {
    height: 32px;
}

.dashboard-details .itb-modal {
    overflow: auto;
    padding-top: 10px;
}

.dashboard-details .itb-modal-dismiss {
    margin: 0 auto;
}