#ipv6-dhcp-active-clients-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
    overflow-x: auto;
}

.ipv6-dhcp-active-clients-list-wrapper {
    overflow-x: auto;
    margin: 25px 0;
    padding: 0
}

.ipv6-dhcp-active-clients-list-wrapper .list {
    margin: 0 auto;
    width: 700px;
}

#ipv6-dhcp-active-clients-page .info-card {
    max-width: 50%;
}

#ipv6-dhcp-active-clients-form {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}
