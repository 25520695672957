#static-routing-page {
    width: var(--width-box-large);
    max-width: var(--max-width-box);
    margin: 0 auto;
    padding-bottom: 40px;
}

#static-routing-edit-page {
    width: var(--width-box-small);
    max-width: var(--max-width-box);
    margin: 0 auto;
    padding-bottom: 40px;
}

#static-routing-edit-page .itb-button-container {
    margin-top: 20px;
    padding: 0;
}

#static-routing-list {
    margin: 25px 0;
    padding: 0
} 

#static-routing-list .list {
    margin: 0 auto;
} 
