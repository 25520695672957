.list {
    margin: 20px;
    text-align: center;
}

.list-headers, .list-line-entry {
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 0 16px;
}

.list-header-item, .list-line-item {
    flex: 1;
    text-align: left;
}

.list-headers {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    background-color: #f4f6f7;
}
.list.responsive .list-headers {
    display: none;
}


.list-header-item {
    position: relative;
    color: #191c23;
    font-size: 15px;
    font-weight: 600;
}
.list-header-item.orderable {
    cursor: pointer;
}

.list-line-entry {
    height: 50px;
    border-bottom: 1px solid #dfe2e6;
}
.list-line-entry label {
    display: none;
}
.list.responsive .list-line-entry {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px;
    margin: 4px auto;
    border: 1px solid #dfe2e6;
    border-radius: 4px;

}
.list.responsive .list-line-entry label {
    display: initial;
    font-weight: bold;
}


.list-line-entry.extended {
    height: 96px
}

.modal-list .list{
    overflow-y: auto;
    max-height: 250px;
}

.modal-list .list-lines {
    padding: 3px 0px;
}

.modal-list .list-header-item{
    padding: 0px 0px;
    text-align: center;
}

.modal-list .list-headers{
    padding: 0px 0px;
}

.modal-list .list-line-item {
    padding: 8px 0px;
    height: 30px;
    text-align: center;
    font-weight: normal;
}

.modal-list .list-line-entry {
    padding: 5px 0px;
}

.list-line-item.tooltip {
    position: relative;
    /* display: inline-block; */
  }
  
.list-line-item.tooltip .tooltiptext {
    visibility: visible;
    text-align: center;
    padding: 5px 0;
    z-index: 1;
    left: 50%;
    transform: translate(-50%, 0);
}

.list.responsive .list-line-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 8px;
}

.list-tooltip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-header-order-icon {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: 10px;
    width: 20px;
    height: 13px;
}


.list-header-order-arrow {
    border: solid #191c23;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
}

.list-header-order-arrow.up {
    position: absolute;
    top: 0;
    transform: rotate(-135deg);
}
.list-header-order-arrow.down {
    position: absolute;
    bottom: 0;
    transform: rotate(45deg);
}

.list-headers .dropdown-header {
    width: 84px;
}

.list-lines .dropdown-options {
    position: relative;
    cursor: pointer;
    margin: 30px;
}

.list-lines .dropdown-icon {
    height: 24px;
}

.list-lines .dropdown-icon:hover {
    background-color: var(--light-gray);
    border-radius: 15px;
}

.list-lines .dropdown-icon.vertical {
    transform: rotateZ(90deg);
}

.list-lines .dropdown-menu {
    display: none;
    position: absolute;
    right: 0;
    background-color: white;
    border: 1px solid #cfd8dc;
    border-radius: 4px;
    min-width: 120px;
    text-align: left;
    z-index: 2;
}

.list-lines .dropdown-menu > div {
    padding: 10px 15px;
}

.list-lines .dropdown-menu > div:hover {
    background-color: var(--light-gray);
}