#voip-dial-plan-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#voip-dial-plan-page form {
    margin: 0 auto;
    margin-top: 25px;
}

#voip-dial-plan-page .info-card {
    margin: 0 auto;
    margin-top: 32px;
}

.dialplan-long-text {
    max-height: 100px;
    word-break: break-all;
    overflow-y: auto;
    padding-bottom: 20px;
    margin-bottom: -20px;
}
