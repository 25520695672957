#ipv6-dhcp-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    padding-bottom: 40px;
}

#ipv6-dhcp-page .dhcp-ipv6-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}

#ipv6-dhcp-page .dhcp-ipv6-info a {
    text-decoration: none;
    color: cornflowerblue;
}

#ipv6-dhcp-page .dhcpv6-active-clients {
    position: relative;
    height: 50px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    box-shadow: var(--darker-shadow);
    margin: 25px 0;
}

#ipv6-dhcp-page .dhcpv6-active-clients span {
    display: block;
    text-align: left;
    font-size: 14px;
    width: 100%;
}

#ipv6-dhcp-page .dhcpv6-active-clients-icon {
    position: relative;
    left: -20px;
    text-align: left;
    padding-left: 20px;
}
#ipv6-dhcp-page .dhcp-active-clients-icon {
    border-left: 2px solid rgb(146, 164, 177);
}

#ipv6-dhcp-page .ipv6-dhcp-server-tbl {
    margin: 25px 0;
}
