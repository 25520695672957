#pon-stats-page {
    margin: 0 auto;
}

#pon-stats-page .subtitle2{
    margin-top: 30px;
    margin-bottom: 10px;
}

#pon-stats-page .info-card {
    width: var(--width-box-small);
    max-width: var(--max-width-box);
    margin: 0 auto;
}