#ipv6-static-route-page {
    max-width: var(--max-width-box);
    width: var(--width-box-large);
    margin: 0 auto;
    overflow-x: auto;
}

.ipv6-static-route-list-wrapper {
    overflow-x: auto;
    margin: 25px 0;
    padding: 0
}

#ipv6-static-route-page .list {
    margin: 0 auto;
    width: 1050px;
}

#ipv6-static-route-page .list-headers, 
#ipv6-static-route-page .list-line-entry  {
    padding: 0px;
}

#ipv6-static-route-page .info-card {
    max-width: 100%;
}

#ipv6-static-route-form {
    max-width: 450px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
}

#ipv6-static-route-edit-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
}

.ipv6-static-route-list-headers {
    width: 100%;
    height: 56px;
    border-radius: 4px;
}

#ipv6-static-route-no-rules .info-card {
    max-width: 50%;
}

#ipv6-static-route-page .ipv6-static-route-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}

#ipv6-static-route-page .ipv6-static-route-info a {
    text-decoration: none;
    color: cornflowerblue;
}

#ipv6-static-route-edit-page .ipv6-static-route-edit-info {
    color: orange;
    margin: 10px 0;
    font-size: 0.79em;
    text-align: center;
}

#ipv6-static-route-edit-page .ipv6-static-route-edit-info a {
    text-decoration: none;
    color: cornflowerblue;
}