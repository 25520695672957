.radio-group {
    position: relative;
    text-align: left;
    margin: 10px 0 25px 0;
}

.radio-group input[type=radio] {
    opacity: 0;
}

.radio-group label {
    padding-left: 32px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

.radio-group label:after, .radio-group label:before {
    position: absolute;
}

.radio-group label::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid var(--light-green);
    left: 15px;
    top: -1px;
    border-radius: 50%;
}

.radio-group label:after {
    content: "";
    display: inline-block;
    height: 6px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: white;
    transform: rotate(-45deg);
    left: 19px;
    top: 2px;
}

.radio-group input[type=radio]:checked + label:after {
    content: '';
}
.radio-group input[type=radio]:checked + label:before{
    content: '';
    background-color: var(--itb-green);
    border-color: var(--itb-green);
}

.radio-group input[type=radio] + label:after {
    content: none;
}

.radio-group.disabled * {
    cursor: not-allowed;
}
.radio-group.disabled *, .radio-group.disabled label::before, .radio-group.disabled input[type=radio]:checked + label:before{
    color: var(--medium-gray);
    fill: var(--medium-gray);
    border-color: var(--medium-gray);
}
.radio-group.disabled input[type=radio]:checked + label:before {
    background-color: var(--medium-gray);
}
